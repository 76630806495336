import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Bio from '../components/bio'

const Musics = ({location, data}) => {
  const { frontmatter, html } = data.markdownRemark
  
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="Alone's music list"/>
      <article className="markdown-css">
        <header>
          <h1>{frontmatter.title}</h1>
        </header>
        <section dangerouslySetInnerHTML={{ __html: html }} />
        <hr/>
      </article>
      <Bio />
    </Layout>
  )
}

export default Musics

export const movieQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages-content/"}, frontmatter: {}, fields: {slug: {eq: "/musiclist/"}}) {
      frontmatter {
        title
      }
      html
    }
  }
`